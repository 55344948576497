<template>
  <div class="px-3 px-md-5 py-1"
       :class="{'bg-theme-danger': showErrors, 'bg-light': !showErrors}">
    <div v-if="errors">
      <div class="sub-lead" v-for="error in errors"> {{ $t(error.path, error.values) }}</div>
    </div>
    <div v-if="!contest.disregardVoterWeight" class="sub-lead">{{ $tc('js.ballot.your_vote_weight', currentVoter.weight) }}</div>
    <div class="d-flex justify-content-between flex-wrap">
      <span class="sub-lead">{{ crossCounter }}</span>
      <span class="sub-lead">{{ $t('js.ballot.selected') }}: {{ selected }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {mapState} from 'vuex'
import SelectionPileValidator from "@aion-dk/js-client/dist/lib/validators/selectionPileValidator";
import BelgiumBallotValidator from "@aion-dk/js-client/dist/lib/validators/belgiumBallotValidator";
import {PropType} from "vue";
import {SelectionPile} from "@aion-dk/js-client/dist/lib/av_client/types";

export default {
  name: "BallotStatus",
  props: {
    contest: {
      type: Object,
      required: true,
    },
    activeSelectionPile: {
      type: Object as PropType<SelectionPile>,
      required: true,
    },
  },
  computed: {
    ...mapState('voting', ['currentVoter']),
    crossCounter() {
      let counter = null;
      let minimumVotes = this.contest.markingType?.minMarks;
      let maximumVotes = this.contest.markingType?.maxMarks;
      let voteVariation = this.contest.markingType?.voteVariation;
      if (voteVariation === 'ranked') {
        counter = minimumVotes === maximumVotes ?
            this.$tc('js.ballot.rank_x_options', minimumVotes) :
            this.$t('js.ballot.rank_between_x_and_y_options', {x: minimumVotes, y: maximumVotes})
      } else {
        counter = minimumVotes === maximumVotes ?
            this.$tc('js.ballot.choose_x_options', minimumVotes) :
            this.$t('js.ballot.choose_between_x_and_y_options', {x: minimumVotes, y: maximumVotes})
      }
      return counter;
    },
    selected() {
      if (!this.activeSelectionPile) return 0;
      return this.activeSelectionPile.optionSelections.length + (this.activeSelectionPile.explicitBlank && 1)
    },
    errors() {
      if (!this.activeSelectionPile) return [];
      let customvalidators : any[] = []

      let errors = new SelectionPileValidator(this.contest).validate(this.activeSelectionPile).map(error => {
        if (error.keys) {
          // We do this in case a key contains a locale string
          Object.keys(error.keys).forEach((key) => {
            if (typeof error.keys[key] === "object") {
              error.keys[key] = error.keys[key][this.$i18n.locale];
            }
          });
        }

        switch (error.message) {
          case "blank":
            return {
              path: `js.error.default.blank_combined_with_other_options`,
              values: {},
            }
          case "too_many":
            return {
              path: `js.error.default.too_many_crosses`,
              values: {maximum: this.contest.markingType?.maxMarks || this.contest.maximumVotes},
            }
          case "exceeded_list_limit":
            return {
              path: `js.components.AVSubmissionHelper.errors.exceeded_list_limit`,
              values: error.keys,
            }
        }
        return {
          path: `js.error.${this.contest.markingType?.voteVariation || this.contest.ruleSet}.${error.message}`,
          values: [],
        }
      })

      if (this.contest.customRulesets.includes("belgian_ballot_rules")) {
        customvalidators.push(new BelgiumBallotValidator(this.contest));
      }

      let customErrors = customvalidators.flatMap( validator => {
        return validator.validate(this.activeSelectionPile).map(error => {
          return {
            path: `js.components.AVSubmissionHelper.errors.${error.message}`,
            values: [],
          }
        })
      })

      const combinedErrors = [...errors, ...customErrors];

      return [...combinedErrors]
    },
    showErrors() {
      return this.errors.length > 0
    },
  },
}
</script>